define("discourse/plugins/discourse-files-section/discourse/files-section-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route('files', {
      path: '/files'
    }, function () {
      this.route('new', {
        path: '/new'
      });
      this.route('show', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/edit/:id'
      });
    });
  }
});