define("discourse/plugins/discourse-files-section/discourse/controllers/files-show", ["exports", "@ember/controller", "@ember/owner", "discourse/components/modal/share-topic"], function (_exports, _controller, _owner, _shareTopic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    actions: {
      sharePost(post) {
        (0, _owner.getOwner)(this).lookup("service:modal").show(_shareTopic.default, {
          model: {
            topic: post
          }
        });
      }
    }
  });
});