define("discourse/plugins/discourse-files-section/discourse/components/file-uploader", ["exports", "@ember/component", "discourse/lib/uppy/uppy-upload", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _uppyUpload, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="btn pad-left no-text {{if this.uploading 'disabled'}}">
    {{d-icon "upload"}}
    <input disabled={{this.uploading}} type="file" class="hidden-upload-field" />
  </label>
  {{#if this.uploading}}
    <span>{{i18n 'upload_selector.uploading'}} {{this.uploadProgress}}%</span>
  {{/if}}
  {{this.fileName}}
  {{input-tip validation=this.validation id="upload-validation"}}
  
  */
  {
    "id": "1KqM63a7",
    "block": "[[[10,\"label\"],[15,0,[29,[\"btn pad-left no-text \",[52,[30,0,[\"uploading\"]],\"disabled\"]]]],[12],[1,\"\\n  \"],[1,[28,[35,2],[\"upload\"],null]],[1,\"\\n  \"],[10,\"input\"],[15,\"disabled\",[30,0,[\"uploading\"]]],[14,0,\"hidden-upload-field\"],[14,4,\"file\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"uploading\"]],[[[1,\"  \"],[10,1],[12],[1,[28,[35,5],[\"upload_selector.uploading\"],null]],[1,\" \"],[1,[30,0,[\"uploadProgress\"]]],[1,\"%\"],[13],[1,\"\\n\"]],[]],null],[1,[30,0,[\"fileName\"]]],[1,\"\\n\"],[1,[28,[35,6],null,[[\"validation\",\"id\"],[[30,0,[\"validation\"]],\"upload-validation\"]]]],[1,\"\\n\"]],[],false,[\"label\",\"if\",\"d-icon\",\"input\",\"span\",\"i18n\",\"input-tip\"]]",
    "moduleName": "discourse/plugins/discourse-files-section/discourse/components/file-uploader.hbs",
    "isStrictMode": false
  });
  class FileUploader extends _component.default.extend(_uppyUpload.default) {
    static #_ = (() => dt7948.g(this.prototype, "fileName", [_tracking.tracked], function () {
      return I18n.t('files.post.file.placeholder');
    }))();
    #fileName = (() => (dt7948.i(this, "fileName"), void 0))();
    id = "elektron-file-upload";
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.set("fileName", this.get('model').get('upload_file_name'));
    }
    validateUploadedFilesOptions() {
      this.fileName = '';
    }
    static #_2 = (() => dt7948.n(this.prototype, "validateUploadedFilesOptions", [_object.action]))();
    uploadDone(upload) {
      this.set("fileName", upload.original_filename);
      this.get('model').set('upload_id', upload.id);
      this.get('model').set('upload_url', upload.url);
      this.get('model').set('upload_file_name', upload.original_filename);
      this.get('model').set('upload', upload);
    }
    static #_3 = (() => dt7948.n(this.prototype, "uploadDone", [_object.action]))();
  }
  _exports.default = FileUploader;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FileUploader);
});