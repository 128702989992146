define("discourse/plugins/discourse-files-section/discourse/controllers/files-edit", ["exports", "discourse-common/utils/decorators", "@ember/controller", "@ember/object"], function (_exports, _decorators, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    // By default we haven't saved anything
    saved: false,
    saveButtonText(isSaving) {
      return isSaving ? I18n.t('saving') : I18n.t('save');
    },
    titleValidation(title, lastValidatedAt) {
      if (this.model.get('titleMissing') && !title) {
        return _object.default.create({
          failed: true,
          reason: I18n.t('files.error.title_missing'),
          lastShownAt: lastValidatedAt
        });
      }
    },
    uploadValidation(upload, lastValidatedAt) {
      if (this.model.get('noUpload') && !upload) {
        return _object.default.create({
          failed: true,
          reason: I18n.t('files.error.no_upload'),
          lastShownAt: lastValidatedAt
        });
      }
    },
    descriptionValidation(description, lastValidatedAt) {
      if (this.model.get('descriptionMissing') && !description) {
        return _object.default.create({
          failed: true,
          reason: I18n.t('files.error.description_missing'),
          lastShownAt: lastValidatedAt
        });
      }
    },
    categoryValidation(file_subcategories, lastValidatedAt) {
      if (this.model.get('categoryMissing') && file_subcategories.length == 0) {
        return _object.default.create({
          failed: true,
          reason: I18n.t('files.error.category_missing'),
          lastShownAt: lastValidatedAt
        });
      }
    },
    actions: {
      validateAndSave() {
        var save = true;
        if (!this.model.get('title')) {
          this.model.set('titleMissing', true);
          this.notifyPropertyChange('model.title');
          save = false;
        }
        if (!this.model.get('upload_url')) {
          this.model.set('noUpload', true);
          this.notifyPropertyChange('model.upload_url');
          save = false;
        }
        if (!this.model.get('description')) {
          this.model.set('descriptionMissing', true);
          this.notifyPropertyChange('model.description');
          save = false;
        }
        if (this.model.get('file_subcategories').length == 0) {
          this.model.set('categoryMissing', true);
          this.notifyPropertyChange('model.file_subcategories');
          save = false;
        }
        if (save) {
          // Trigger the route's save action
          this.send('save');
        }
        return save;
      },
      chooseCategory(chosen) {
        this.model.set("file_subcategories", []);
        this.model.file_category.file_subcategories.forEach(function (subcategory) {
          subcategory.set("filter", false);
        });
        this.model.available_file_categories.forEach(function (category) {
          if (chosen.id != category.id) {
            category.set("filter", false);
          }
        });
        if (chosen.get("filter")) {
          chosen.set("filter", false);
          this.model.set("file_category", {
            file_subcategories: []
          });
        } else {
          chosen.set("filter", true);
          this.model.set("file_category", chosen);
        }
      },
      chooseSubcategory(chosen) {
        var model = this.model;
        this.model.file_category.file_subcategories.forEach(function (subcategory) {
          if (subcategory.id == chosen.id && !chosen.filter) {
            chosen.set("filter", true);
            model.file_subcategories.addObject(chosen);
          } else if (subcategory.id == chosen.id && chosen.filter) {
            chosen.set("filter", false);
            model.file_subcategories.forEach(function (file_subcategory) {
              if (file_subcategory.id == chosen.id) {
                model.file_subcategories.removeObject(file_subcategory);
              }
            });
          }
        });
        this.notifyPropertyChange('model.file_subcategories');
      }
    }
  }, [["method", "saveButtonText", [(0, _decorators.default)("model.isSaving")]], ["method", "titleValidation", [(0, _decorators.default)('model.title', 'lastValidatedAt', 'model.titleMissing')]], ["method", "uploadValidation", [(0, _decorators.default)('model.upload_url', 'lastValidatedAt', 'model.noUpload')]], ["method", "descriptionValidation", [(0, _decorators.default)('model.description', 'lastValidatedAt', 'model.descriptionMissing')]], ["method", "categoryValidation", [(0, _decorators.default)('model.file_subcategories.[]', 'lastValidatedAt', 'model.categoryMissing')]]]));
});