define("discourse/plugins/discourse-files-section/discourse/routes/files-edit", ["exports", "discourse/models/user", "@ember/routing/route", "discourse-common/lib/get-owner"], function (_exports, _user, _route, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    path: "files",
    setupController: function (controller, model) {
      // model.reply = model.content;
      controller.set('saved', false);
      this._super(controller, model);
    },
    beforeModel(transition) {
      if (!_user.default.current()) {
        const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
        router.transitionTo('files.index');
      }
    },
    actions: {
      save: function () {
        var self = this;
        var model = this.controller.model;
        model.set('isSaving', true);
        var file_subcategory_ids = [];
        model.get('file_subcategories').forEach(function (file_subcategory) {
          file_subcategory_ids.addObject(file_subcategory.id);
        });
        $.ajax({
          url: "/" + self.path + "/" + model.get('id'),
          type: "PUT",
          data: {
            user_id: _user.default.current().id,
            description: model.get('description'),
            title: model.get('title'),
            upload_id: model.get('upload_id'),
            upload_url: model.get('upload_url'),
            upload_file_name: model.get('upload_file_name'),
            file_subcategory_ids: file_subcategory_ids
          }
        }).then(function (result) {
          model.set('isSaving', false);
          const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
          router.transitionTo(self.path + '.index');
        });
      }
    }
  });
});