define("discourse/plugins/discourse-files-section/discourse/models/file", ["exports", "discourse/lib/preload-store", "@ember/object"], function (_exports, _preloadStore, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend({
    available_file_categories: [],
    file_subcategories: [],
    file_category: {},
    isSaving: false,
    deleted: false,
    init: function () {
      this.set('available_file_categories', _preloadStore.default.get('file_categories'));
      this.set('isSaving', false);
      this.set('deleted', false);
    }
  });
});