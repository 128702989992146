define("discourse/plugins/discourse-files-section/discourse/routes/files-show", ["exports", "discourse/plugins/discourse-files-section/discourse/models/file", "@ember/routing/route"], function (_exports, _file, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    path: "files",
    setupController: function (controller, model) {
      this._super(controller, model);
    },
    model(params, transition) {
      var self = this;
      return $.ajax({
        url: "/" + self.path + "/" + params.id,
        type: "GET"
      }).then(function (result) {
        var postModel = _file.default.create(result.post);
        postModel.set('shareUrl', '/files/' + postModel.id);
        return postModel;
      });
    }
  });
});