define("discourse/plugins/discourse-files-section/discourse/helpers/unless-last", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = unlessLast;
  function unlessLast(array, params) {
    if (array[array.length - 1] == params.object) {
      return "";
    } else {
      return params.content;
    }
  }
});