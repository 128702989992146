define("discourse/plugins/discourse-files-section/discourse/components/files-feed", ["exports", "discourse/mixins/scrolling", "@ember/component", "@ember/runloop"], function (_exports, _scrolling, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FilesFeed extends _component.default.extend(_scrolling.default) {
    didInsertElement() {
      this._readjustScrollPosition();
      this.bindScrolling();
    }
    willDestroyElement() {
      $(window).scrollTop(0);
      this.unbindScrolling();
    }
    saveScrollPosition() {
      this.session.set('filesListScrollPosition', $(window).scrollTop());
    }
    scrolled() {
      this.saveScrollPosition();
      if (this.isScrolledToBottom()) {
        this.getMore();
      }
    }
    isScrolledToBottom() {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 50 && $(document).height() > $(window).height()) {
        return true;
      }
      return false;
    }
    _readjustScrollPosition() {
      const scrollTo = this.session.get('filesListScrollPosition');
      if (scrollTo && scrollTo >= 0) {
        (0, _runloop.schedule)('afterRender', () => $(window).scrollTop(scrollTo + 1));
      } else {
        // scheduleOnce('afterRender', this, this.loadMoreUnlessFull);
      }
    }
  }
  _exports.default = FilesFeed;
});