define("discourse/plugins/discourse-files-section/discourse/models/files", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend({
    files: [],
    available_file_categories: [],
    file_category: {},
    file_subcategories: [],
    offset: 0,
    limit: 10,
    sort_order: 'desc',
    init: function () {
      this.set('files', []);
      this.set('available_file_categories', []);
      this.set('offset', 0);
      this.set('limit', 10);
      this.set('sort_order', 'desc');
    }
  });
});