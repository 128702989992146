define("discourse/plugins/discourse-files-section/discourse/routes/files-index", ["exports", "discourse/plugins/discourse-files-section/discourse/models/files", "discourse/plugins/discourse-files-section/discourse/models/file", "discourse/plugins/discourse-files-section/discourse/models/category", "discourse/models/user", "discourse/lib/preload-store", "discourse/routes/discourse", "discourse-common/lib/get-owner"], function (_exports, _files, _file, _category, _user, _preloadStore, _discourse, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    path: "files",
    setupController: function (controller, model) {
      controller.set('model', model);
      controller.send('gotMore');
      this._super(controller, model);
    },
    model() {
      var self = this;
      var model = _files.default.create();
      return this.getCategories(model).then(function () {
        return self.fetchData(model);
      });
    },
    getCategories(model) {
      return $.ajax({
        url: "/files/categories",
        type: "GET"
      }).then(function (result) {
        var file_categories = [];
        result.file_categories.forEach(function (category) {
          file_categories.addObject(_category.default.create(category));
        });
        _preloadStore.default.store("file_categories", file_categories);
        model.set("available_file_categories", _preloadStore.default.get('file_categories'));
      });
    },
    fetchData(model) {
      var self = this;
      var file_subcategory_ids = [];
      model.get("file_subcategories").forEach(function (file_subcategory) {
        file_subcategory_ids.addObject(file_subcategory.id);
      });
      return $.ajax({
        url: "/files",
        type: "GET",
        data: {
          file_category_id: model.get("file_category") ? model.get("file_category").id : undefined,
          file_subcategory_ids: file_subcategory_ids,
          offset: model.get('offset'),
          limit: model.get('limit'),
          sort_order: model.get('sort_order')
        }
      }).then(function (result) {
        result.files.forEach(function (file) {
          var postModel = _file.default.create(file);
          if (_user.default.current()) {
            postModel.set('canEdit', postModel.user_id == _user.default.current().id || _user.default.current().admin);
            postModel.set('canDelete', postModel.user_id == _user.default.current().id || _user.default.current().admin);
          }
          postModel.set('shareUrl', '/files/' + postModel.id);
          model.files.addObject(postModel);
        });
        model.set('offset', result.offset);
        model.set('limit', result.limit);
        model.set('sort_order', result.sort_order);
        return model;
      });
    },
    actions: {
      editPost(post) {
        post.available_file_categories.forEach(function (available_file_category) {
          if (post.file_category.id == available_file_category.id) {
            available_file_category.set("filter", true);
            post.set("file_category", _category.default.create(available_file_category));
            post.file_category.set("filter", true);
            post.file_category.file_subcategories.forEach(function (available_file_subcategory) {
              post.file_subcategories.forEach(function (file_subcategory) {
                if (file_subcategory.id == available_file_subcategory.id) {
                  available_file_subcategory.filter = true;
                }
              });
            });
          }
        });
        const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
        router.transitionTo(this.path + ".edit", post);
      },
      deletePost(post) {
        var model = this.controller.model;
        $.ajax({
          url: "/files/" + post.id,
          type: "DELETE"
        }).then(function (result) {
          if (result.success) {
            for (var i = 0; i < model.files.length; i++) {
              if (post.id == model.files[i].id) {
                model.files[i].set('deleted', true);
                setTimeout(function () {
                  $(".files .posts .row.deleted").height('0');
                }, 100);
                setTimeout(function () {
                  model.files.removeObject(model.files[i]);
                }, 500);
                break;
              }
            }
          }
        });
      },
      getMore() {
        var self = this;
        var limit = this.controller.model.get('limit'),
          offset = this.controller.model.get('offset');
        this.controller.model.set('offset', offset + limit);
        this.fetchData(this.controller.model).then(function () {
          self.controller.send('gotMore');
        });
      },
      filterCategory(category) {
        var self = this;
        var model = this.controller.model;
        model.set("file_subcategories", []);
        if (model.file_category.file_subcategories) {
          model.file_category.file_subcategories.forEach(function (subcategory) {
            subcategory.set("filter", false);
          });
        }
        model.available_file_categories.forEach(function (available_file_category) {
          if (category.id != available_file_category.id) {
            available_file_category.set("filter", false);
          }
        });
        if (category.get("filter")) {
          category.set("filter", false);
          model.set("file_category", {});
        } else {
          category.set("filter", true);
          model.set("file_category", category);
        }
        model.set("files", []);
        model.set("offset", 0);
        model.set("limit", 20);
        this.fetchData(model).then(function () {
          self.controller.send("gotMore");
        });
      },
      filterSubcategory(subcategory) {
        var self = this;
        var model = this.controller.model;
        for (var i = 0; i < model.file_category.file_subcategories.length; i++) {
          if (model.file_category.file_subcategories[i].subcategory == subcategory.subcategory) {
            if (!model.file_category.file_subcategories[i].filter) {
              model.file_category.file_subcategories[i].set("filter", true);
              model.file_subcategories.addObject(model.file_category.file_subcategories[i]);
            } else {
              model.file_category.file_subcategories[i].set("filter", false);
              model.file_subcategories.removeObject(model.file_category.file_subcategories[i]);
            }
          }
        }
        model.set("files", []);
        model.set("offset", 0);
        model.set("limit", 20);
        this.fetchData(model).then(function () {
          self.controller.send("gotMore");
        });
      }
    }
  });
});