define("discourse/plugins/discourse-files-section/discourse/initializers/file-post-menu", ["exports", "discourse/lib/plugin-api", "virtual-dom", "discourse/plugins/discourse-files-section/discourse/components/files-section-download-button", "discourse/lib/deprecated"], function (_exports, _pluginApi, _virtualDom, _filesSectionDownloadButton, _deprecated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "file-post-menu",
    initialize: function (container, application) {
      (0, _pluginApi.withPluginApi)('1.34.0', api => {
        const transformerRegistered = api.registerValueTransformer("post-menu-buttons", _ref => {
          let {
            value: dag,
            context: {
              post,
              firstButtonKey,
              // key of the first button
              secondLastHiddenButtonKey,
              // key of the second last hidden button
              lastHiddenButtonKey // key of the last hidden button
            }
          } = _ref;
          dag.add("download", _filesSectionDownloadButton.default, {
            before: firstButtonKey
          });
        });
        const silencedKey = transformerRegistered && "discourse.post-menu-widget-overrides";
        (0, _deprecated.withSilencedDeprecations)(silencedKey, () => {
          api.addPostMenuButton('download', post => {
            if (post.upload_url) {
              return {
                icon: 'download',
                className: 'download-file',
                title: 'files.download',
                position: 'first',
                contents: (0, _virtualDom.h)('a', {
                  href: post.upload_url,
                  download: post.upload_file_name,
                  target: '_blank'
                })
              };
            }
          });
        });
      });
    }
  };
});