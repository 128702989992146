define("discourse/plugins/discourse-files-section/discourse/components/files-section-download-button", ["exports", "@glimmer/component", "@ember/object", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FilesSectionDownloadButton extends _component.default {
    static shouldRender(args) {
      return args.post?.upload_url;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div>
          <DButton
            class="post-action-menu__files_download"
            ...attributes
            href={{this.args.post.upload_url}}
            @icon="download"
          />
        </div>
      
    */
    {
      "id": "lD1Pf+Eq",
      "block": "[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"post-action-menu__files_download\"],[17,1],[16,6,[30,0,[\"args\",\"post\",\"upload_url\"]]]],[[\"@icon\"],[\"download\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-files-section/discourse/components/files-section-download-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = FilesSectionDownloadButton;
});