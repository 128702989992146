define("discourse/plugins/discourse-files-section/discourse/models/category", ["exports", "discourse/plugins/discourse-files-section/discourse/models/subcategory", "@ember/object"], function (_exports, _subcategory, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend({
    init() {
      var self = this;
      this.set('subcategories', function () {
        for (var i = 0; i < self.file_subcategories.length; i++) {
          self.file_subcategories[i] = _subcategory.default.create(self.file_subcategories[i]);
        }
        ;
      }());
    }
  });
});